import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AlertService } from '../_alert';

declare let $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(
              private formBuilder: FormBuilder,
              private http: HttpClient,
              private alertService: AlertService
              ) { }
  
  apiUrl = environment.apiUrl;
  contactForm: FormGroup;
  submitted = false;
  isDisabled = false;
  showLoader = false;
    
  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', Validators.required],
      subject: ['', Validators.required],
      message: []
    });    
  }
  
  get f() { return this.contactForm.controls; }
  
  onSubmit(event) {

    this.submitted = true;
    // stop here if form is invalid
    if (this.contactForm.invalid) {
      return;
    }
    console.log('submit');
    this.showLoader = true;
    const formData = new FormData(event.target);
      
    //this.http.post(this.apiUrl + 'contact_us', formData).subscribe(data => {
    this.http.post('https://www.worldittechnologies.com/webservice.php', formData).subscribe(data => {    
        this.isDisabled = false;
        this.showLoader = false;

        let ClientsData: any = data;

        if (ClientsData.success == true) {
          // this.successcallback = ClientsData.message;
          this.alertService.clear();
          this.alertService.success(ClientsData.msg);
          this.submitted = false;
          this.contactForm.reset();
          // this.router.navigate(['/thankyou']);
        } else {
          // this.errorcallback = ClientsData.message;
          this.alertService.clear();
          this.alertService.error(ClientsData.msg);
        }
    });
  }

}
