import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})

export class BlogDetailsComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }
  
  DM: boolean = false;
  WD: boolean = false;
  IOI: boolean = false;

  ngOnInit() {
    let bloglink = this.activatedRoute.snapshot.params.bloglink;
    console.log(bloglink);// OUTPUT 1534
    
    if(bloglink === 'digital-marketing-trends-in-2021')
    {
      this.DM = true;
    }
    if(bloglink === 'unmatchable-Web-design-services')
    {
      this.WD = true;
    }
    if(bloglink === 'ioi-and-website-design-combination')
    {
      this.IOI = true;
    }    
  }
}
