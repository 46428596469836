import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AlertService } from '../_alert';

declare let $: any;

@Component({
  selector: 'app-iot',
  templateUrl: './iot.component.html',
  styleUrls: ['./iot.component.css']
})
export class IotComponent implements OnInit {

  constructor(
              private formBuilder: FormBuilder,
              private http: HttpClient,
              private alertService: AlertService
            ) { }
            
  apiUrl = environment.apiUrl;
  getInTouchForm: FormGroup;
  submitted = false;
  isDisabled = false;
  showLoader = false;

  ngOnInit() {
    this.getInTouchForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', Validators.required],
      service: ['', Validators.required],
      company_name: [''],
      skypeid: [''],
      message: [''],
    });
  }
  
  get f() { return this.getInTouchForm.controls; }
  
  onSubmit(event) {
    this.submitted = true;
		
    // stop here if form is invalid
    if (this.getInTouchForm.invalid) {
      return;
    }
		
    this.showLoader = true;
		
    const formData = new FormData(event.target);
		
   // this.http.post(this.apiUrl + 'enquiry', formData).subscribe(data => {
    this.http.post('https://www.worldittechnologies.com/webservice.php', formData).subscribe(data => {
    
      this.isDisabled = false;
      this.showLoader = false;
			
      let ClientsData: any = data;

      if (ClientsData.success == true) {
        // this.successcallback = ClientsData.message;
        this.alertService.clear();
        this.alertService.success(ClientsData.msg);
        this.submitted = false;
        this.getInTouchForm.reset();
        // this.router.navigate(['/thankyou']);
      } else {
        // this.errorcallback = ClientsData.message;
        this.alertService.clear();
        this.alertService.error(ClientsData.msg);
      }
    });
  }

  scroll() {
    document.querySelector('#contactform').scrollIntoView({ behavior: 'smooth', block: 'center' });
    
 }

}
